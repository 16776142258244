import { Movable } from "./Movable";
import { Accelerator } from "./Accelerator";
import { AngleDecider } from "./AngleDecider";
import { toRadians } from "../../../util/Math";
import { Position } from "./Position";

export class CreatureMove {
  constructor(
    public movable: Movable,
    private accelerator: Accelerator,
    private angleDecider: AngleDecider,
  ) {}

  updateAndGetNext = (width: number, height: number): Position => {
    const speed = this.accelerator.updateAndGetSpeed();
    const angle = this.angleDecider.updateAndGetDegree();
    const addX = Math.cos(toRadians(angle)) * speed;
    const addY = Math.sin(toRadians(angle)) * speed;
    return this.movable.move(addX, addY, width, height);
  };
}
