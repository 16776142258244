import { random } from "../../../util/Math";

export class AngleDecider {
  private resetCount: number;
  constructor(private maxResetCount: number, private degree: number) {
    this.resetCount = random(1, maxResetCount);
  }

  updateAndGetDegree = (): number => {
    this.resetCount--;
    if(this.resetCount < 0) {
      this.resetCount = random(1, this.maxResetCount);
      this.degree = random(1, 360);
    }
    return this.degree;
  };
}
