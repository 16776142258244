import React, { useEffect, FC } from "react";
import p5 from "p5";

interface CanvasProps {
  sketch: (p:p5) => void
}


const Canvas: FC<CanvasProps> = ( { sketch }) => {
  useEffect(() => {
    new p5(sketch);

  document.oncontextmenu = (e) => {
    e.preventDefault();
  }
  }, [sketch]);
  
  return <div style={{width:"100%", height:"100%"}}></div>;
};
export default Canvas;
