import React from 'react';
import './App.css';
import Canvas from "./p5/usecase/Canvas";
import Main from './p5/usecase/Main';
import {Header} from './Header';

function App() {
  return (
    <div className="App">
      <Header />
      <Canvas sketch={Main} />
    </div>
  );
}

export default App;
