import { v4 } from "uuid";
import { CreatureMove } from "../../domain/move/CreatureMove";
import { Movable } from "../../domain/move/Movable";
import { Accelerator } from "../../domain/move/Accelerator";
import { AngleDecider } from "../../domain/move/AngleDecider";
import { Position } from "../../domain/move/Position";
import P5, { Color } from "p5";
import { Ageble } from "../../domain/life/Ageble";
import { random, range } from "../../../util/Math";
import { cloneDeep } from "lodash-es";

export class Creature {
  public creatureMove: CreatureMove;
  private married: boolean = false;
  public uuid: string = v4();

  constructor(private p: P5, position: Position, public color: Color, public ageble: Ageble) {
    this.creatureMove = new CreatureMove(
      new Movable(position),
      new Accelerator(2, 1, 0.7, 0.5),
      new AngleDecider(250, random(0, 360)),
    );
  }

  public updateAndDraw = () => {
    const next = this.creatureMove.updateAndGetNext(this.p.width, this.p.height);
    this.ageble.aging();
    this.p.fill(
      this.p.hue(this.color),
      this.p.saturation(this.color),
      this.p.brightness(this.color),
      100 - this.ageble.rate(),
    );
    this.p.ellipse(next.x, next.y, 5, 5);
  };

  public reproduction = (target: Creature, num: number): Creature[] => {
    if (
      this.uuid === target.uuid ||
      this.married ||
      target.married ||
      this.ageble.age < 100 ||
      target.ageble.age < 100
    ) {
      return [];
    }

    if (this.isCollision(target)) {
      target.married = true;
      this.married = true;
      return range(1, num).map(
        (x) =>
          new Creature(
            this.p,
            cloneDeep(this.creatureMove.movable.position),
            this.createColor(target.color),
            new Ageble(random(1000, 2000)),
          ),
      );
    }
    return [];
  };

  private isCollision = (target: Creature): boolean => {
    return (
      10 >
      this.p.dist(
        target.creatureMove.movable.position.x,
        target.creatureMove.movable.position.y,
        this.creatureMove.movable.position.x,
        this.creatureMove.movable.position.y,
      )
    );
  };

  private createColor = (color: Color): Color => {
    const hueA = this.p.hue(this.color);
    const hueB = this.p.hue(color);

    const r = this.p.random(0, 100);

    if(r > 95) {
      return this.p.color(this.p.random(255), 255, 255);
    }

    const s = Math.round(this.p.random(12)) % 3;

    switch (s) {
      case 0:
        return this.color;
      case 1:
        return color;
      case 2:
        return this.p.color((hueA + hueB) / 2, 255, 255);
      default:
        return this.p.color((hueA + hueB) / 2, 255, 255);
    }
  };
}
