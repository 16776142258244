export const range = (start: number, end: number) =>
  Array.from({ length: end - start + 1 }, (v, k) => k + start);

export const toRadians = (degree: number): number => degree * (Math.PI / 180);

export const random = (min: number, max: number): number => min + Math.random() * (max - min);

export const constrain = (val: number, min: number, max: number): number => {
  if (val > max) {
    return max;
  }
  if (val < min) {
    return min;
  }
  return val;
};
