import { Creature } from "./Creature";
import P5, { Color } from "p5";
import { Ageble } from "../../domain/life/Ageble";
import { random } from "../../../util/Math";
import { Position } from "../../domain/move/Position";
import { SpawnRing } from "./SpawnRing";
import { cloneDeep, range } from "lodash-es";
import { SoundPlayer } from "../../domain/sound/SoundPlayer";

export class CreatureManager {
  constructor(private p: P5) {}

  public creatures: Creature[] = [];
  private spawnRings: SpawnRing[] = [];

  public add = (position: Position, color: Color): Creature[] => {
    this.creatures.push(new Creature(this.p, position, color, new Ageble(random(2000, 3000))));
    return this.creatures;
  };

  public addMany = (position: Position): Creature[] => {
    range(0, 50).map((x) =>
      this.creatures.push(
        new Creature(
          this.p,
          cloneDeep(position),
          this.p.color(this.p.random(0, 255), 255, 255),
          new Ageble(random(2000, 3000)),
        ),
      ),
    );
    return this.creatures;
  };

  public rip = () => {
    this.creatures = this.creatures.filter((x) => x.ageble.isLive());
    this.spawnRings = this.spawnRings.filter((x) => x.isLive());
  };

  public draw = (soundPlayer: SoundPlayer) => {
    this.creatures.forEach((x) => {
      this.creatures = this.creatures.concat(
        this.creatures.flatMap((y) => {
          const spawn = x.reproduction(
            y,
            this.p.random(1, this.p.constrain(100 - this.creatures.length, 1, 20)),
          );
          if (spawn.length > 0) {
            this.spawnRings.push(
              new SpawnRing(this.p, cloneDeep(y.creatureMove.movable.position), y.color),
            );
            soundPlayer.play(
              x.creatureMove.movable.position,
              this.creatures.length,
              this.p.height,
              this.p.width,
              this.p.red(x.color),
              this.p.green(x.color),
              this.p.blue(x.color),
            );
          }

          return spawn;
        }),
      );
      x.updateAndDraw();
    });

    this.spawnRings.forEach((s) => s.draw());
  };
}
