import { toRadians, random, constrain } from "../../../util/Math";

export class Accelerator {
  constructor(
    private maxPow: number,
    private minPow: number,
    private maxDegreeAdder: number,
    private minDegreeAdder: number,
  ) {}

  private nowPow: number = random(this.minPow, this.maxPow);
  public speed: number = 0;
  private speedDegree: number = random(0, 360);
  private degreeAdder = random(this.minDegreeAdder, this.maxDegreeAdder);

  public updateAndGetSpeed = () => {
    this.addSpeedDegree();
    return constrain(Math.sin(toRadians(this.speedDegree)), 0, 1) * this.nowPow;
  };

  private addSpeedDegree = () => {
    this.speedDegree += this.degreeAdder;
    if(this.speedDegree > random(180, 250)) {
      this.speedDegree = 90;
      this.nowPow = random(this.minPow, this.maxPow);
      this.degreeAdder = random(this.minDegreeAdder, this.maxDegreeAdder);
    }
  };
}
