import { Position } from "../domain/move/Position";
import { CreatureManager } from "./Creature/CreatureManager";
import { SoundPlayer } from "../domain/sound/SoundPlayer";
import { range } from "../../util/Math";

import p5 from "p5";
require("p5/lib/addons/p5.sound");

const Main = (p: p5) => {
  let creatureManager: CreatureManager = new CreatureManager(p);
  let soundPlayer: SoundPlayer;
  let notInitialized = true;

  const start = (x: number, y: number) => {
    if (soundPlayer.isReady()) {
      if (notInitialized) {
        creatureManager.addMany(new Position(x, y));
        try {
          const isFs = p.fullscreen();
          p.fullscreen(!isFs);
        } catch (e) {
          console.log(e);
        }
        notInitialized = false;
      }
    }
  };

  p.preload = () => {
    soundPlayer = new SoundPlayer(
      range(0, 42).map((x) => `/sound/harp/${x}`),
      range(0, 42).map((x) => `/sound/celesta/${x}`),
      range(0, 42).map((x) => `/sound/marimba/${x}`),
      ["/sound/sea"],
    );
  };

  p.setup = () => {
    p.createCanvas(p.displayWidth, p.displayHeight, "p2d");
    p.background(0);
    p.colorMode(p.HSB, 255, 255, 255, 100);
    p.blendMode(p.NORMAL);
  };

  p.draw = () => {
    if (soundPlayer.isNotReady()) {
      waitingForReady(p, soundPlayer);
      return;
    }

    if (notInitialized) {
      waitingForClickOrTap(p);
      return;
    }

    p.fill(0, 10);
    p.noStroke();
    p.rect(0, 0, p.width, p.height);

    p.noStroke();
    p.fill(90, 255, 255);
    creatureManager.draw(soundPlayer);
    creatureManager.rip();
  };

  p.mousePressed = () => {
    start(p.mouseX, p.mouseY);
    if (p.mouseButton === p.LEFT && notInitialized === false && soundPlayer.isReady()) {
      creatureManager.add(new Position(p.mouseX, p.mouseY), p.color(p.random(0, 255), 255, 255));
    }
  };

  p.touchStarted = () => {
    start(p.mouseX, p.mouseY);
    if (notInitialized === false && soundPlayer.isReady()) {
      creatureManager.add(new Position(p.mouseX, p.mouseY), p.color(p.random(0, 255), 255, 255));
    }
  };

  p.keyPressed = () => {
    if (notInitialized === false && soundPlayer.isReady()) {
      try {
        const isFs = p.fullscreen();
        p.fullscreen(!isFs);
      } catch (e) {
        console.log(e);
      }
    }
  };
};

export default Main;

const waitingForReady = (p: p5, soundPlayer: SoundPlayer) => {
  p.background(0);
  p.stroke(255, 0, 255);
  p.fill(255, 0, 255);
  p.textAlign("center");
  p.text("NOW LOADING", p.width / 2, p.height / 2 - 100);
  p.text(
    `${soundPlayer.loaded.length} / ${soundPlayer.allSoundFileNum}`,
    p.width / 2,
    p.height / 2,
  );
};

const waitingForClickOrTap = (p: p5) => {
  p.background(0);
  p.stroke(255, 0, 255);
  p.fill(255, 0, 255);
  p.textAlign("center");
  p.text("CLICK ANY WHERE TO START", p.width / 2, p.height / 2);
};
