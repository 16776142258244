
export class Ageble {
  public age: number = 0;
  constructor(public lifeSpan: number) {
  }

  public aging = (): number => {
    this.age++;
    return this.age;
  };

  public isLive = (): boolean => {
    return this.age <= this.lifeSpan;
  };

  public isDead = (): boolean => {
    return this.age > this.lifeSpan;
  };

  public isOver = (age: number): boolean => {
    return this.age > age;
  }

  public rate = (): number => {
    return (this.age / this.lifeSpan) * 100;
  }
}
