import { Position } from "./Position";

export class Movable {
  constructor(public position: Position) {}

  moveTo(toX: number, toY: number): Position {
    this.position.x = toX;
    this.position.y = toY;
    return this.position;
  }

  move(addX: number, addY: number, width: number, height: number): Position {
    this.position.x += addX;
    this.position.y += addY;

    if (this.position.x < 0) {
      this.position.x = 0;
    }
    if (this.position.x > width) {
      this.position.x = width;
    }

    if (this.position.y < 0) {
      this.position.y = 0;
    }
    if (this.position.y > height) {
      this.position.y = height;
    }

    return this.position;
  }
}
