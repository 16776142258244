import React, {useEffect, useState} from 'react';


export const Header = () => {
  
  const [headerState,setHeaderState] = useState(false);
  const [timeIdState,setTimeIdState] = useState<number | undefined>(undefined);
  const [headerStyleState, setHeaderStyleState] = useState<React.CSSProperties>({
    position: "absolute",
    height: "0",
    transition: "all 0.5s",
    overflow: "hidden",
    backgroundColor: "#111144",
    width: "100%",
    color: "white",
  });

  const changeHeaderHeight = (height: string, minHeight: string) => {
    setHeaderStyleState({
      position: "absolute",
      minHeight,
      height,
      transition: "all 0.5s",
      overflow: "hidden",
      backgroundColor: "#111144",
      width: "100%",
      color: "white",
    });
  }


  useEffect(() => {
    window.addEventListener("mousemove", () => setHeaderState(true));
    return window.removeEventListener("mousemove", () => setHeaderState(true));
  }, []);

    useEffect(() => {
    window.addEventListener("touchstart", () => setHeaderState(true));
    return window.removeEventListener("touchstart", () => setHeaderState(true));
  }, []);

  useEffect(() => {

    if(timeIdState !== undefined) {
      clearTimeout(timeIdState);
      setTimeIdState(undefined);
    }

    const timeOutId: number = window.setTimeout(() => setHeaderState(false), 5000);
    setTimeIdState(timeOutId)

    return () => {
        clearTimeout(timeOutId);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [headerState]);

  useEffect(() => {
    if(headerState) {
      return changeHeaderHeight("2rem", "2rem");
    }
    changeHeaderHeight("0", "0");
  }, [headerState])

  return (<>
    <div style={headerStyleState}>
      <a href="https://twitter.com/soundbiotope" style={{color: "#9999bb"}}>@soundbiotope</a>
    </div>
    </>);
}