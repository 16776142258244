import { Position } from "../../domain/move/Position";
import { Howl } from "howler";
import { PitchDetector } from "./PitchDetector";

export class SoundPlayer {
  private harps: Howl[];
  private celesta: Howl[];
  private marimba: Howl[];
  public bgmFiles: Howl[];
  public loaded: boolean[] = [];
  public allSoundFileNum: number;

  private pitchDetector: PitchDetector = new PitchDetector(
    [0, 1, 2].flatMap((x) => [0, 2, 4, 7, 9].map((p) => p + 12 * x)),
  );

  private addDone = () => {
    this.loaded.push(true);
  };

  constructor(harpUrls: string[], celestaUrls: string[], marimba: string[], bgmFiles: string[]) {
    this.allSoundFileNum = harpUrls.length + celestaUrls.length + marimba.length + bgmFiles.length;
    this.harps = harpUrls.map(
      (x) =>
        new Howl({
          src: [`${x}.mp3`],
        }),
    );
    this.harps.forEach((x) => x.once("load", () => this.addDone()));

    this.celesta = celestaUrls.map(
      (x) =>
        new Howl({
          src: [`${x}.mp3`],
        }),
    );
    this.celesta.forEach((x) => x.once("load", () => this.addDone()));

    this.marimba = marimba.map(
      (x) =>
        new Howl({
          src: [`${x}.mp3`],
        }),
    );
    this.marimba.forEach((x) => x.once("load", () => this.addDone()));

    this.bgmFiles = bgmFiles.map(
      (x) =>
        new Howl({
          src: [`${x}.mp3`],
          loop: true,
        }),
    );
    this.bgmFiles.forEach((x) => x.once("load", () => this.addDone()));
    this.bgmFiles.forEach((x) => {
      x.volume(0.2);
      x.play();
    });
  }
  public play = (
    position: Position,
    num: number,
    height: number,
    width: number,
    r: number,
    g: number,
    b: number,
  ) => {
    const index = this.pitchDetector.getPitch(position.y, num, height, this.harps.length);

    const pan = (position.x * 2 - width) / width;

    if (r > g && r > b) {
      this.playSound(this.marimba[index], 0.3, pan);
      return;
    }

    if (b > g && b > r) {
      this.playSound(this.celesta[index], 0.5, pan);
      return;
    }

    this.playSound(this.harps[index], 0.3, pan);
  };

  public isReady() {
    return this.loaded.length === this.allSoundFileNum;
  }
  public isNotReady() {
    return !this.isReady();
  }

  private playSound = (howl: Howl, vol: number, pan: number): void => {
    howl.stereo(pan);
    howl.volume(vol);
    howl.play();
  };
}
