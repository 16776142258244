import { Position } from "../../domain/move/Position";
import P5, { Color } from "p5";
import { Ageble } from "../../domain/life/Ageble";

export class SpawnRing {
  constructor(private p: P5, private position: Position, private color: Color) {}

  private age: Ageble = new Ageble(50);

  public draw = (): void => {
    this.p.noFill();
    this.p.stroke(this.p.hue(this.color), this.p.saturation(this.color), this.p.brightness(this.color), 100 - this.age.age * 2);
    this.p.ellipse(this.position.x, this.position.y, this.age.age * 2, this.age.age * 2);
    this.age.aging();
  };

  public isLive = (): boolean => {
    return this.age.isLive();
  }
}
