import {random} from "../../../util/Math";

export class PitchDetector {
  private past: number = -1;
  private sameCount = 0;
  constructor(private scale: number[]) {}

  public getPitch = (
    y: number,
    creatureSize: number,
    height: number,
    sourceSoundSize: number,
  ): number => {
    const sourceAbout = this.makePitch(y, height);
    const pastCheckedAbout = this.checkPast(sourceAbout);
    const about = this.checkUpperLimit(pastCheckedAbout);
    const pitch = this.scale[about];
    const tonal = Math.floor(creatureSize / 30) * 2;
    let index = pitch + tonal;

    if (index >= sourceSoundSize) {
      return Math.floor(random(0, sourceSoundSize - 1));
    }
    return index;
  };

  private makePitch = (y: number, height: number): number => {
    return Math.floor(((height - y) / height) * (this.scale.length - 3));
  };

  private checkPast = (about: number) => {
    if (this.past === about) {
      this.sameCount = this.sameCount + 1;
      return about + this.sameCount;
    }
    this.past = 0;
    return about;
  };

  private checkUpperLimit = (pastCheckedAbout: number) => {
    if (pastCheckedAbout > this.scale.length - 1) {
      return this.scale.length - 1;
    }
    return pastCheckedAbout;
  };
}
